@import '~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references';
.store-product-card {
    outline: none;
    padding-left: 0px;
    padding-right: 8px;
    margin-top: 10px;   
    .display-inline-flex
    {
        display: flex;
        align-items: stretch;
    }
    .store-product-details
    {       
        flex-grow: 8; 
        padding: 5px 15px 0px 0px;
        .PKIdValue
        {
            word-break: break-all;
        }
    }

    &:focus {
        .card {
            outline: 1px solid $input-focus-border-color;
        }
    }

    .card {
        margin-left: 0px;
        margin-right: 10px;
        box-shadow: none;
        background-color: $ms-color-card-background;
        
        .label-value {
            font-size: $ms-para-font-size;
            padding: 0px;
            padding-top: 5px;
            cursor: inherit;
            font-weight: 500;
        }

        .description-value {
            min-height: 65px;
            max-height: 65px;
            font-size: $ms-para-font-size;
            color: $ms-color-black;
            cursor: inherit;
            padding: 0px;
            text-align: left;           
        }

        .card-body {           
            text-align: justify;
            background-color: $ms-color-card-background;
            padding: 0px 14px 10px 0px;          
        }
    }

    .card-selected-icon {
        visibility: collapse;
        color: $blue;
        vertical-align: top;
    }
    .card-selected-remove-icon {
        visibility: collapse;
        vertical-align: top;
        float: right;
        padding: 5px;
        font-size: 12px;
        color: black;
    }     
}
.store-product-card.attach-selected-product {
  .card {
    outline: 1px solid #63B8FF;
  }
  .card-selected-icon {
    visibility: visible !important;
  }  
}
.store-product-card.detach-selected-product {
  .card {
    //outline: 1px solid red ;    
    border-left:5px solid red ;  
    .card-body
    {
       padding-left:6px !important; 
    }  
    
  }
}
.store-product-card.attach-new-product{
    .card {       
   // outline: 1px solid #27AE60 ;    
    border-left: 5px solid #27AE60 ;
    .card-body
    {
        cursor: default;
        padding-left:6px !important;  
    }       
    }
    .card-selected-remove-icon {
        cursor: pointer;
        visibility: visible !important;
      } 
}
.detach-selected-product , .attach-selected-product{
    &:focus {
     .card {         
         box-shadow: 0px 0px 10px 0px #626262 !important;   
        }
      }
    }
    .product-console-status-show
    {
        visibility:visible;
        display: inline-flex;
        background: #E5E5E5	;
        font-size:12px; 
        text-align: center;
        font-weight: 500;
        width: 100%;
        padding: 0px 0px 8px 0px;
        max-height: 40px;
        border-top: 1px solid #B5B5B5;
        .status-count-label
        {   
          color: #383838;      
        } 
        .label-value
        {
        padding: 0px;
        }
       .status-more-then-two
       {            
            display: grid;
            text-align: center;
            float: left;
            width: 33%;
        }
        .two-status
        {            
             display: grid;
             text-align: center;
             float: left;
             width: 48%;
             .label-value 
             {
              padding: 7px 10px 0px 0px;
             }
             .status-count-label
             {
              padding: 0px 10px 7px 0px;
             }
         }
         .single-status
         {            
              display: grid;
              text-align: center;
              float: left;
              width: 100%;
              .label-value 
              {
               padding: 7px 25px 0px 0px;
              }
              .status-count-label
              {
               padding: 0px 25px 7px 0px;
              }
          }
          .console-status-selection
          {            
               display: table;
               text-align: center;
               float: left;
               width: 100%;
               .label-value
               {
                display: table-cell;
                vertical-align: middle;
                height: 30px;
                padding: 10px 0px 10px 15px;
               }
               .status-count-label
               {
                   visibility: collapse;
               }

           }
    }
    .product-console-status-hide
    {
        visibility: collapse;
        height: 0px;
    }