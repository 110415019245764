@import '~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references';
.create-detach-nav-items{
    .nav-item-status
    {
        a {
        cursor: default;
        }
    }
    //For small screen
    @media (min-width: 320px) and (max-width:479px){
        .wizard-nav-item {
            .nav-item-status{
                display: none;
            }
        }
}
  //For Medium sized Screen
  @media (min-width: 480px) and (max-width:639px){
    .wizard-nav-item {
        .nav-item-status{
            display: none;
        }
    }
  }
    //For Large sized Screen
    @media (min-width: 640px) and (max-width:700px){
        .wizard-nav-item {
            .nav-item-status{
                display: none;
            }
        }
    }
    @media (min-width: 320px) and (max-width:470px){
        .split-button{
            margin-bottom: 40px;
        }
    }

}
.create-detach-wizard{
    .wizard-step-header{
        h2{
            margin-bottom: 0px;
        }
    }
}
