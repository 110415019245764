.device-selection-section
{ 
    cursor: pointer;
    color: #626262;
    font-size: 18px;
    padding: 3px 0px 5px 10px;    
    .ms-Icon
    {
        vertical-align: middle;
        padding: 3px 5px 0px 0px;
    } 
    .device-selection-text
    {   vertical-align: middle;
        padding: 2px 0px 0px 0px;
    } 
    
}
.ms-List
    {
      .ms-List-surface
      {
        .ms-List-page ,
        {
          .ms-List-cell , .ms-DetailsList
          {
            .ms-DetailsRow-cell ,.ms-DetailsHeader-cell
            {
                .ms-DetailsRow-check , .ms-DetailsRow-check--isHeader 
                {
                cursor:pointer;
                }
            }
          }
        }
      }
}