@import '~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references';
.device-product-list
{
    .link-items {       
        font-size: 20px;
        color: $blue; 
        margin-bottom:-4px;       
      }     
      .expand-collapse{       
        .collapse-icon{
          padding-right: 3px;
          font-size: 16px;
          font-weight: bold
        }
      }
      .unAvailableProduct {
        font-weight: lighter;
        font-size: large;
        vertical-align: middle;
        display: inline-block;
      }
  
}
@media only screen and (max-width: 639px) {
  .link-items {       
    font-size: 20px;
    color: $blue; 
    margin-bottom:10px !important;       
  }   
}