.search-Devices{
    cursor: default ;
    .disable-card{
        .card-body{
            cursor: default;
            background:#f0f0f0;
            .label-header , .label-value ,.sku-description-value
            {
                cursor: default !important;
            }
            .hover-link {
            cursor: pointer !important;
           }
          opacity: 1;  
        }
    }
        .label-header ,.label-value   {
            cursor: default;
         }
         .card-body
         {
            cursor: default;
         } 
         .hover-link{
            cursor: pointer ;
        }     
}