@import '~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references';
.device-result
{
.select-console {
        .select-console-criteria {                              

        .advance-search-option{
            margin:10px 0px 0px 0px;  
        }

        .advance-option-inputs{
            margin:10px 0px 0px 0px;  
        }        
        .action-buttons{
            margin:10px 0px 0px 0px;            
        }                   

        .toggle-option
        {
            .ms-Pivot
            {
                width: 100%;
                height: 32px
            }           
            .ms-Button--action{
                width: 50%;
                height: 32px
            }   
        }
        .spacing{
            margin-right: 10px;
        }
    }            
}
.search-result  
{
    margin-top: 15px;
    padding-top: 15px; 
    border-top: 1px solid #c8c8c8; 
    .search-result-status
    {
    margin: 0px;
    }
    .selected-items{
        padding: 0px 10px 10px 8px;
    }
}   

}
    //For small screen  
    @media (min-width: 320px) and (max-width:787px){
        .wizard-step-footer{
            margin-bottom:90px;
        }
    }  
     //For small screen  
     @media (min-width: 320px) and (max-width:479px){
        .console-filter {
            padding-right:0px;
        }
    }     