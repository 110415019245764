.confirmation-popup{
    .popup-grid {
        margin-bottom: 25px;       
        max-width: 650px;
       .main-message {
          text-align:center;
          font-size:15px;
          margin-bottom:5px; 
        }
        .confirmation-message {
        text-align:center;
        font-size:14px;
        margin-bottom:25px;    
        }
    }
     //For small screen
     @media (min-width: 320px) and (max-width:479px){
      .popup-grid {
        max-width: 320px;
      }
     }
     //For Medium sized Screen
     @media (min-width: 480px) and (max-width:639px){
      .popup-grid {
        max-width: 450px;
      }
      .iconRight{
        margin-left:-8px;
      }
      .iconLeft{
        margin-left:9px;
      }
     }
    .details-header{    
      margin-top: 25px;
      padding: 5px 0px 10px 30px;        
      background: white;
      text-align:center;
      font-size: 24px; 
      color: #00688B; 
      float: left;
      width: 90%;       
    }
  
    .details-close{
      background: white;   
      text-align: center;
      border: 0px;   
      float: right;  
      margin-top:10px;
      font-weight: 600;
      color: #666666;
      .ms-Button-icon{ 
        font-size: 18px;   
      }
    }
    .details-body{
        margin: 10px;    
        min-width: 600px;        
    }

    
  }