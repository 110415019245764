@import '~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references';
.advance-option-inputs
{
    .filterpanel
    {        
        padding-bottom: 10px;        
    }
}
.toggle-option{
    .ms-Button:focus{
        border:2px solid white;
    }
    
}