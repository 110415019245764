@import "~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references";
.device-product-selection-step-container {
  .wizard-step-header {
    margin-bottom: 0px !important;
  }
  .add-products-mobile-link {
    display: none;
  }
  .add-products-link {
    padding: 10px 0px 0px 0px;
    font-size: 20px;
    color: $blue;
    .add-store-icon
    {
      cursor: pointer;
      font-size: 18px;
      padding: 0px 2px 5px 0px;
      vertical-align: middle;
    }
  }
  .reset-products{
    margin-right: 15px;
    .reset-icon{
      padding-right: 3px;
      font-size: 16px;
      font-weight: bold
    }
  }
  .expand-collapse{
    margin-right: 15px;
    .collapse-icon{
      padding-right: 3px;
      font-size: 16px;
      font-weight: bold
    }
  }
@media only screen and (max-width: 639px) {
  .add-products-link {
    display: none;
  }
    .add-products-mobile-link {
     display: inline-flex;
     padding: 15px 0px 15px 0px;
     font-size: 20px;
     color: $blue;
     .add-store-icon
     {
       cursor: pointer;
       font-size: 18px;
       padding: 0px 2px 5px 0px;
       vertical-align: middle;
     }
    }

  }

  @media (min-width: 320px) and (max-width:566px){   
    .previousButton .iconRight{
    margin-bottom: 50px;
    }
  }
}
