@import '~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references';
.device-product-details
{
.selectiondatagrid {  
 // border-bottom: 1px solid #C8c8c8;
 
    .unAvailableProduct {
      font-weight: lighter;
      font-size: large;
      vertical-align: middle;
      display: inline-block;
    }

}
.onDetach-BundleConfirmation
{
  padding: 0px 10px 0px 0px;
  .storeproductdatalist 
  {
    .store-product-card
    {
    padding-left: 10px;
    padding-right: 0px;
    .card-body
    {
      cursor: pointer;
     padding:0px !important;
 
    .store-product-details 
    {    
     padding: 0px 5px 0px 5px; 
     .label-value{
        padding: 15px 0px 0px 0px !important; 
      }
      .description-value{
       padding: 6px 0px 6px 0px;
       min-height: 74px;  
       max-height: 74px;      
       }
     }
    }
  }  
}
}
.accordion 
{
  .store-product-heading-each-row , .sku-console-heading-each-row
  {
   display: none;
 } 
  cursor: pointer ;
  border-top:0px solid #CCC;
  margin: 10px 0px 10px 0px;
  .accordion-header
  {
    width: auto;
    margin-top: 5px;
    background: #f1f1f1;
    border: 1px solid #CCC;      
    .sku-console-section-heading
    {
      color: #626262;
      font-size: 16px;     
      font-weight:600;
    }
    .store-count-section-heading
    {
      color: #626262;
      font-size: 16px;
      padding: 0px 0px 0px 38px;
      font-weight:400;
      .attach-count ,.deatch-count
      {
        font-weight: 600;
        
      }
    }
    .accordion-header-text
    {
      width: 100%;
      border: 0px solid #CCC;        
    }
    .accordion-header-icon
    {
    vertical-align: middle;      
    color: #626262;
    }
  }
  .accordion-header-modified
  {
    border-left:5px solid #FA922A; 
  }
  .accordion-body
  {
    cursor: default;
    width: auto;
    padding: 0px 0px 10px 10px;
    border-left: 1px solid #CCC;
    border-right: 1px solid #CCC;
    border-bottom: 1px solid #CCC;  
  }
}
}


@media only screen and (max-width: 639px) {
    .store-product-heading , .sku-console-heading
    {
     display: none;
   } 
   .store-count-section-heading
   {
    padding: 0px 0px 0px 7px !important;
   }  
  .removepaddLft20 ,.removepaddLft30 , .store-product-card
  {
    padding-left: 0px !important;
  }
  .store-product-heading-each-row , .sku-console-heading-each-row
  {
  display: inline-block !important;
  width: 97% !important;
  } 
}
.selectiondatagrid:last-of-type {
  border-bottom: 0px solid $ms-color-white;
}
