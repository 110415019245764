@import '~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references';
.device-product-mapping{
   margin-right: 2px;
  .selection-detach-flow
  {

  border-bottom: 1px solid #C8c8c8;
  padding: 0px 0px 10px 10px !important; 
  .store-product-card 
  {
    .card-body
    {   
    padding: 5px 0px 10px 10px; 
    }
  }
  .unAvailableProduct {
    font-weight: lighter;
    font-size: large;
    vertical-align: middle;
    display: inline-block;
    padding: 70px 0px 0px 0px; 
  }
  .store-product-heading-each-row , .sku-console-heading-each-row
   {
    display: none;
  } 
}
  
  .accordion 
  {
    cursor: pointer;
    border-top:0px solid #CCC;
    margin: 10px 0px 10px 0px;
    .accordion-header
    {
      margin-top: 5px;
      background: #f1f1f1;
      border: 1px solid #CCC;      
      .sku-console-section-heading
      {
        color: #626262;
        font-size: 16px;     
        font-weight:600;
      }
      .store-count-section-heading
      {
        color: #626262;
        font-size: 16px;
        padding: 0px 0px 0px 38px;
        font-weight:400;
        .attach-count ,.deatch-count
        {
          font-weight: 600;
          
        }
      }
      .accordion-header-text
      {
        width: 100%;
        border: 0px solid #CCC;   
        padding: 10px 0px;     
      }
      .accordion-header-icon
      {
      vertical-align: middle;         
      color: #626262;
      }
    }
    .accordion-header-modified
    {
      border-left:5px solid #FA922A; 
    }
    .accordion-body
    {
      cursor: default;
      padding: 0px;
      border-left: 1px solid #CCC;
      border-right: 1px solid #CCC;
      border-bottom: 1px solid #CCC;
    }
  }

}
@media only screen and (max-width: 639px) {
  .removepaddLft20
  {
    padding-left: 0px !important;
  }
    .store-product-heading , .sku-console-heading
    {
     display: none;
   }  
   .selection-detach-flow {
    border-bottom: 1px solid #888888;
   }
   .store-product-heading-each-row , .sku-console-heading-each-row
   {
    display: inline-block !important;
    width: 97% !important;
  } 
  .unAvailableProduct
  {
    padding: 5px !important;
  }
  .store-count-section-heading
   {
     padding: 0px 0px 0px 7px !important;
   }  
}
.selection-detach-flow:last-of-type {
  border-bottom: 0px solid $ms-color-white;
}

