.store-product-additon-panel
{
    .product-save-button{
        margin-right: 3.5%;
    }
    
     .panel-header
    {
        font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
        font-size: 24px;
        font-weight: lighter;
        margin: 10px 0px 10px 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #C8c8c8;

        
    }
    .ms-Panel-content
    {
    padding: 0px 10px 0px 10px;
    .sku-console-selection-panel
    {
        max-height: 300px;
        overflow-y: auto;
        background: #f1f1f1;
        margin: 10px 0px 10px 0px;
        .ms-DetailsHeader
        {
            background: #d7d7d7;
        }
    }
    .store-product-selection-panel
    {
        .store-product-container
            {
           .selected-items
           {
            margin: 10px 2px 0px 15px;
            padding: 0px;
           }
           .ms-SearchBox 
           {
               margin: 0px 8px 0px 8px;
           }
        }
        
        .pager
        {    padding: 0px;
            .pagination
            {
            .pageno-input
             {
            padding-right: 10px;
             }
            }
        }     
        .ms-SearchBox ,.action-buttons ,.search-result-data ,.search-result-status
        {
           margin-left: 10px;
           width: 100%; 
        }
        



    }    
}
    .ms-Panel-footer
    {
        .ms-Panel-footerInner
            { 
                border-top:1px solid #c8c8c8; 
                padding: 5px 10px 15px 10px;
            }
    }
}