@import '~@ec-oem/ec.oem.oa3.ui.core/styles/variables/references';

.attachments-Card
{
    border: 1px solid $ms-color-card-border;
    max-height: 350px;
    overflow-y: auto;
    
    .card-title 
    {
        padding: 10px;
        background:$ms-color-card-background;
        color: $ms-color-black;
    }
    .product-image
    {
        padding: 5px;

    }
    .store-product-name
    {
       padding: 15px 0px 0px 0px;
    }
    .attachments-details {
       border-top: 1px solid;
        border-color: $ms-color-card-background;
        margin: 5px;        
      }
      .attachments-details:nth-child(2){ 
        border-top: 0px solid !important; 
        margin-top: 1px;
      }
      
}