@import "~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references";

.order-confirmation{
    text-align: center;
    font-family: $ms-font-family-fallbacks;
    padding-top: 50px;
    .status-msg-header{
        font-size: 29px;
        margin-top: 10px;
        font-weight: 100;
    }
   
    .status-msg{
        font-size: 16px;       
    }
    
    .split-button{
        margin:10px 0px 50px 50px;
        display: inline-block;       
        .homeIcon{
            border: 1px solid #5E5E5E;
            font-size: 12px;
        &:focus{
            border: 2px solid $ms-color-blue;
            }
        }
        //For small screen
        @media (min-width: 320px) and (max-width:490px){
            .homeIcon{
            margin-bottom: 10px;
            margin-right:4px;
            margin-left:0px;
            }
        }
          //For medium screen
          @media (min-width: 491px) and (max-width:533px){
            .homeIcon{
                margin-bottom: 10px;
                margin-right:15px;
                margin-left:0px;
                }
          }
        .bundleIcon{
            font-size: 12px;
            margin:60px;
            margin-top:-1px;
        }
        //For small screen
        @media (min-width: 320px) and (max-width:490px){
            .bundleIcon{
               margin-bottom: 10px;
               margin-left:4px;
               margin-right:10px;
            }
        }
            //For medium screen
            @media (min-width: 491px) and (max-width:513px){
                .bundleIcon{
                    margin-bottom: 10px;
                    margin-left:15px;
                    margin-right:10px;
                }
            }
    }
}
//For small screen
@media (min-width: 320px) and (max-width:700px){
    .order-confirmation{
        padding: 0px;       
           
    }
}