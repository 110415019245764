.transaction-details-card
{
    .search-transaction-card 
    {    
    cursor: default;
    .card
    {
        cursor: default;
        padding:3px 0px 5px 0px; 
    }
    .card-row
    {
        margin: 5px;
        border-bottom: 1px solid #C8c8c8;
        width: auto;

    }    
   .card-body
  {
    cursor: default;
    padding: 0px 0px 0px 10px !important; 
  }
  @media (min-width: 320px) and (max-width:570px){
    .card-body
    {
      padding: 0px 5px 0px 10px !important ;   
    }
    }
}
.transaction-card
{  .card-body
    {
     padding: 5px 0px 5px 0px;       
    }
}
.transaction-info
{
    display: grid;
    .card-body
    {
    padding: 0px;
    }
}
}
.search-card
{  
    .card
    {
        .card-body
        {
            .transaction-id-value
            {
                max-width: 135px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            }
            .no-border-bottom
            {
            border-bottom: 0px solid #C8c8c8;
            }
        }
}   
.search-transaction-card:nth-child(3n) {
    .card{
      margin: 10px 0px 0px 0px;
      }
   }
   @media (max-width: 700px) and (min-width: 568px)
   {
      .search-transaction-card:nth-child(3n)
       {
            .card{
            margin: 10px 10px 0px 0px;
            }
        }      
   }
   @media (max-width: 567px) and (min-width: 480px)
   {
      .search-transaction-card:nth-child(3n) {
          .card{
              margin: 10px 10px 0px 0px;
             }
        }  
        .search-transaction-card:nth-child(2n) {
             .card{
                  margin: 10px 0px 0px 0px;
                 }
        }       
        .search-transaction-card {
            .card{
                 margin: 10px 10px 0px 0px;
            }
         } 
   }
   @media (max-width: 479px) and (min-width: 320px)
   {
        .search-transaction-card:nth-child(3n) {
         .card{
          margin: 10px 0px 0px 0px;
           }
        }   
        .search-transaction-card {
          .card{
           margin: 10px 0px 0px 0px !important;
            }
         } 
   }
   